import React from "react";
import { graphql, withPrefix } from "gatsby"
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import Img from "gatsby-image/withIEPolyfill";

const Terms = props => {
	const { title, img } = props.data.markdownRemark.frontmatter;
	const { html } = props.data.markdownRemark;
	return (
		<Layout bodyClass="page-promotions" path={props.location.pathname}>
			<SEO title={title} />
			<div className="container pt-4 pt-md-10">
				<div className="row justify-content-start">
					<div className="col-12">
						<div className="service service-single">
							<h1 className="title">{title}</h1>
							<div
								className="content"
								dangerouslySetInnerHTML={{ __html: html }}
							/>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
		  img {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      html
    }
  }
`;


export default Terms;